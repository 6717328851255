body {
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000000;
  z-index: 1;
}

::-webkit-scrollbar-thumb {
  background: #505050;
  border-radius: 3px;
}
